#main-wrapper[data-layout="horizontal"] {
  // Footer
  //
  .footer {
    background: transparent;
    border-top: 0;
    text-align: center;
  }

  // Fixed Header Layout
  //
  &[data-header-position="fixed"] {
    .topbar {
      position: fixed;
      width: 100%;
    }

    .left-sidebar {
      padding-top: $topbar-height;
    }
  }

  // Fixed Header Layout
  //
  &[data-sidebar-position="fixed"] {
    .topbar {
      position: fixed;
      width: 100%;
    }

    .left-sidebar {
      position: fixed;
      padding-top: $topbar-height;
    }

    .page-wrapper {
      padding-top: $horizontal-pagewrapper-padding-top-sm;
    }
  }
}

// ###############################################
// Horizontal Layout
// ###############################################
// Mobile above screen
//
@include media-breakpoint-only(lg) {
  #main-wrapper[data-layout="horizontal"] {
    // Topbar Header
    //
    .topbar .top-navbar .navbar-header {
      &[data-logobg="skin1"],
      &[data-logobg="skin2"],
      &[data-logobg="skin3"],
      &[data-logobg="skin4"],
      &[data-logobg="skin5"] {
        background: transparent;
      }

      &[data-logobg="skin6"] {
        background: transparent;

        .navbar-brand {
          .dark-logo {
            display: inline;
          }

          .light-logo {
            display: none;
          }
        }

        .nav-toggler,
        .topbartoggler {
          color: $body-color;
        }
      }
    }

    // Topbar Collapse
    .topbar .navbar-collapse {
      &[data-navbarbg="skin1"],
      &[data-navbarbg="skin2"],
      &[data-navbarbg="skin3"],
      &[data-navbarbg="skin4"],
      &[data-navbarbg="skin5"],
      &[data-navbarbg="skin6"] {
        background: transparent;
      }
    }
  }
}

// ###############################################
// Desktop Screen
// ###############################################
@include media-breakpoint-only(lg) {
  &[data-boxed-layout="boxed"] {
    .page-breadcrumb {
      padding: $horizontal-boxed-page-breadcrumb-padding-lg;
    }

    .page-wrapper > .container-fluid {
      padding: $horizontal-boxed-page-breadcrumb-container-padding-lg;
    }
  }
}

// ###############################################
// Above Tablet
// ###############################################
@include media-breakpoint-up(lg) {
  #main-wrapper[data-layout="horizontal"] {
    background: $body-bg;

    // Topbar
    //
    .topbar {
      transition: 0s;
    }

    // Horizontal with boxed layout
    //
    &[data-boxed-layout="boxed"] {
      // Set width of topbar, sidebar & page-wrapper
      //
      .top-navbar,
      .scroll-sidebar,
      .page-wrapper {
        position: relative;
        max-width: $boxed-width;
        margin: 0 auto;
      }

      // Sidebar
      //
      .sidebar-nav {
        #sidebarnav {
          flex-wrap: wrap;

          > .sidebar-item > .has-arrow:after {
            display: block;
          }
        }

        // .sidebar-item {
        // 	flex: 1 1 0;
        // }
      }
    }

    // Fixed Sidebar Layout
    //
    &[data-sidebar-position="fixed"] {
      .page-wrapper {
        padding-top: $horizontal-pagewrapper-padding-top-lg;
      }
    }

    // Horizontal Layout
    //

    // Topbar
    //
    .topbar .top-navbar .navbar-header {
      width: $horizontal-navbar-brand-width;
    }

    .topbar .sidebartoggler {
      display: none;
    }

    // Left Sidebar
    //
    .left-sidebar {
      position: relative;
      width: 100%;
      height: auto;
      padding-top: 0;
      z-index: 45;
      transition: 0s;

      .scroll-sidebar {
        height: $sidebarheight;
      }
    }

    // Scroll sidebar
    //
    .scroll-sidebar {
      height: auto;
    }

    // Sidebar Nav
    //
    .sidebar-nav {
      #sidebarnav {
        display: flex;
        width: 100%;

        // Sidebar Item
        //
        .sidebar-item {
          position: relative;
          width: auto;
        }

        > .sidebar-item {
          border-right: 1px solid rgba(0, 0, 0, 0.1);

          > .has-arrow:after {
            transform: rotate(-135deg) translate(0, -50%);
          }

          &:last-child > .first-level {
            right: 0;
            left: auto;
          }

          > .two-column + .first-level {
            width: $sidebar-first-level-width;

            > .sidebar-item {
              float: $lft;
              vertical-align: top;
              width: 50%;
            }
          }
        }

        // Sidebar Item inner ul
        //
        > .sidebar-item .first-level,
        > .sidebar-item .second-level {
          position: absolute;
          left: 0;
          top: auto;
          width: $sidebar-li-width;
          padding-bottom: 0;
          display: none;
          z-index: 100;
          box-shadow: $horizontal-list-shadow;
          background: $white;
        }

        // Mega Dropdown
        //
        > .mega-dropdown {
          position: static;

          .first-level {
            width: 100%;

            ul {
              background-color: $white;

              > li {
                width: 25%;
                float: $lft;
              }
            }
          }
        }

        > .sidebar-item:hover {
          background: rgba(0, 0, 0, 0.025);
        }

        // ul first level
        //
        > .sidebar-item:hover .first-level,
        > .sidebar-item:hover .first-level.collapse {
          display: block;

          &:after {
            position: absolute;
            content: "";
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background: rgba(0, 0, 0, 0.025);
            z-index: -1;
          }
        }

        > .sidebar-item .second-level {
          left: $sidebar-li-width;
          top: 0;
          max-height: 300px;
          overflow: overlay;
        }

        > .sidebar-item:last-child > .first-level .second-level,
        > .sidebar-item .first-level .right-side-dd .second-level {
          right: $sidebar-li-width;
          top: 0;
          left: auto;
        }

        .first-level ul > .sidebar-item:hover .second-level {
          display: block;
        }

        // >.sidebar-item > .has-arrow:after,
        .nav-small-cap,
        .sidebar-footer,
        .user-pro,
        .badge {
          display: none;
        }
      }
    }
  }
}

// ###############################################
// Tablet Layout
// ###############################################

@include media-breakpoint-only(md) {
  #main-wrapper[data-layout="horizontal"] {
    // icon display-none
    //
    // .sidebar-nav > ul > .sidebar-item > .sidebar-link i {
    //     display: none;
    // }

    .sidebar-nav #sidebarnav > .sidebar-item {
      flex: auto;
    }
  }

  // Fixed Sidebar Layout
  //
  &[data-sidebar-position="fixed"] {
    .left-sidebar {
      overflow: auto;
    }
  }
}

// ###############################################
// Tablet Layout
// ###############################################
@include media-breakpoint-down(lg) {
  //
  // Main wrapper
  #main-wrapper[data-layout="horizontal"] {
    // Sidebar type=mini-sidebar
    // ###############################################
    &[data-sidebartype="mini-sidebar"] {
      // Left sidebar
      //
      .left-sidebar,
      .left-sidebar .sidebar-footer {
        left: -$sidebar-width-full;
      }
    }

    // Open sidebar
    // ###############################################
    &.show-sidebar {
      .left-sidebar,
      .left-sidebar .sidebar-footer {
        left: 0;
      }
    }

    // Sidebar position fixed with vertical layout && both with fixed
    // ###############################################
    &[data-layout="vertical"][data-sidebar-position="fixed"],
    &[data-layout="vertical"][data-header-position="fixed"][data-sidebar-position="fixed"] {
      // Topbar
      //
      .topbar .top-navbar {
        .navbar-collapse {
          position: relative;
          top: $topbar-height;
        }
      }
    }
  }
}

@include media-breakpoint-only(md) {
  #main-wrapper[data-layout="horizontal"] {
    .topbar .top-navbar {
      .navbar-collapse.show,
      .navbar-collapse.collapsing {
        display: block;
        width: 100%;
        border-top: 1px solid $border-color;
      }

      .navbar-nav {
        flex-direction: row;

        .dropdown {
          position: static;
        }
      }

      .navbar-nav > .nav-item > .nav-link {
        padding: 0 10px;
      }

      .navbar-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
      }

      .dropdown-menu {
        position: absolute;
        width: 100%;

        .with-arrow {
          display: none;
        }
      }
    }
  }
}
