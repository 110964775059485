/*******************/
/*Chat widget*/
/*******************/
.chat-box {
    overflow: auto;
}

/*******************/
/*Chat widget*/
/*******************/

.chat-list {
    li {
        list-style: none;

        .chat-img {
          display: inline-block;
          width: 45px;
          vertical-align: top;

            img {
              width: 45px;
              border-radius: 100%;
            }
        }

        .chat-content {
            width: calc(100% - 1px);
            display: inline-block;
            .box {
              border-radius: 4px;
              display: inline-block;
              padding: 10px;
              margin-bottom: 3px;
              color: $gray-800;
              background: $gray-100;
            }
        }

        .chat-time {
            width: 80px;
            font-size: 13px;
        }

        &.odd {
            .chat-content {
              text-align: right;
              width: 100%;
            }

            .box {
              clear: both;
              color: $white;
              background: $info;
            }
        }

        &.odd+.odd {
            margin-top: 0px;
        }

        &.reverse {
            text-align: right;

            .chat-time {
                text-align: left;
            }

            .chat-content {
                padding-left: 0px;
                padding-right: 15px;
            }
        }
    }

    &.chat-app {
        margin: 0px;
        padding: 0px;
        overflow: auto;
        position: relative;

        .left-part {
            width: 260px;
        }

        .chat-item {
            list-style: none;
            margin-top: 30px;

            .chat-img {
                display: inline-block;
                width: 45px;
                vertical-align: top;

                img {
                    width: 45px;
                    border-radius: 100%;
                }
            }

            .chat-content {
                width: calc(100% - 1px);
                display: inline-block;

                .box {
                    border-radius: 4px;
                    display: inline-block;
                    padding: 10px;
                    margin-bottom: 3px;
                    color: $gray-800;
                    background: $gray-100;
                }
            }

            .chat-time {
                display: block;
                font-size: 10px;
                color: $gray-700;
                margin: 5px 0 15px 65px;
            }

            &.odd {
                .chat-content {
                    text-align: right;
                    width: calc(100% - 0px);
                }

                .chat-time {
                    text-align: right;
                }

                .box {
                    clear: both;
                    background: $info;
                }
            }

            &.odd+.odd {
                margin-top: 0px;
            }

            &.reverse {
                text-align: right;

                .chat-time {
                    text-align: left;
                }

                .chat-content {
                    padding-left: 0px;
                    padding-right: 15px;
                }
            }
        }
    }
}

@include media-breakpoint-down(sm) {
    .chat-list.chat-app {
        .left-part {
            width: 230px;
        }

        .chat-item {
            .chat-content {
                width: calc(100% - 7px);
            }

            .show-left-part {
                &.left-part-open {
                    left: 269px;
                    top: 73px;
                }
            }
        }
    }
}

@include media-breakpoint-down(md) {
    .chat-list {
        li {
            .chat-content {
                width: calc(100% - 80px);
            }
        }
    }
}



/*******************/
/*Chat widget*/
/*******************/

.message-box {
    .message-widget {
        a {
            &:hover {
                background: $light;
            }

            .user-img {
                width: 45px;

                .profile-status {
                    border: 2px solid $white;
                    height: 10px;
                    left: 33px;
                    position: absolute;
                    top: -1px;
                    width: 10px;
                }

                .online {
                    background: $success;
                }

                .busy {
                    background: $danger;
                }

                .away {
                    background: $warning;
                }

                .offline {
                    background: $warning;
                }
            }

            .mail-desc,
            .time {
                color: $body-color;
            }
        }
    }
}