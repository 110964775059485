//
// Form label
//
form label {
  font-weight: $font-weight-normal;
  margin-bottom: 0;
}

// #########################################
// Form custom  validation
// #########################################
.error {
  .form-control {
    border-color: $danger;
  }

  .help-block {
    color: $danger;
  }
}

.validate {
  .form-control {
    border-color: $success;
  }

  .help-block {
    color: $success;
  }
}

// #########################################
// Form date picker
// #########################################
@media (min-height: 33.875em) {
  .picker--opened .picker__frame {
    position: absolute;
    top: 30%;
    bottom: auto;
  }
}

.dtp > .dtp-content > .dtp-date-view > header.dtp-header,
.dtp div.dtp-date,
.dtp div.dtp-time,
.dtp table.dtp-picker-days tr > td > a.selected {
  background: $info;
}

.dtp .p10 > a {
  color: $white;
}

.datepicker .day,
.datepicker .dow {
  padding: 5px 10px;
}

// #########################################
// Form Image Cropper
// #########################################
.docs-buttons .btn,
.docs-data .input-group {
  margin-bottom: 5px;
}

// #########################################
// Default perfect scrollbar use this class
// #########################################
.scrollable {
  position: relative;
}

//
// Form material inputs
//
.form-material {
  .form-group {
    overflow: hidden;
  }

  .form-control {
    background-color: rgba(0, 0, 0, 0);
    // background-position: center bottom, center calc(100% - 1px);
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: 0 2px, 100% 1px;
    padding: 0;
    transition: background 0s ease-out 0s;
    height: calc(2.25rem + 2px);
    border-bottom: 1px solid $border-color !important;
  }

  & .form-control,
  & .form-control.focus,
  & .form-control:focus {
    background-image: linear-gradient($info, $info),
      linear-gradient($input-border-color, $input-border-color);
    border: 0 none;
    border-radius: 0;
    box-shadow: none !important;
    float: none;
    background-position: bottom;
  }

  & .form-control.focus,
  & .form-control:focus {
    background-size: 100% 2px, 100% 1px;
    outline: 0 none;
    transition-duration: 0.3s;
  }
}

.form-control-line {
  .form-group {
    overflow: hidden;
  }

  .form-control {
    border: 0px;
    border-radius: 0px;
    padding-left: 0px;
    border-bottom: 1px solid $input-border-color;

    &:focus {
      border-bottom: 1px solid $info;
    }
  }
}

.btn-file {
  overflow: hidden;
  position: relative;
  vertical-align: middle;

  > input {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    opacity: 0;
    filter: alpha(opacity=0);
    font-size: 23px;
    height: 100%;
    width: 100%;
    direction: ltr;
    cursor: pointer;
    border-radius: 0px;
  }
}

.fileinput {
  .input-group-addon {
    border-right: 1px solid $border-color;
  }
}

.fileinput .form-control {
  padding-top: 7px;
  padding-bottom: 5px;
  display: inline-block;
  margin-bottom: 0px;
  vertical-align: middle;
  cursor: text;
}

.fileinput .thumbnail {
  overflow: hidden;
  display: inline-block;
  margin-bottom: 5px;
  vertical-align: middle;
  text-align: center;
}

.fileinput .thumbnail > img {
  max-height: 100%;
}

.fileinput .btn {
  vertical-align: middle;
}

.fileinput-exists .fileinput-new,
.fileinput-new .fileinput-exists {
  display: none;
}

.fileinput-inline .fileinput-controls {
  display: inline;
}

.fileinput-filename {
  vertical-align: middle;
  display: inline-block;
  overflow: hidden;
}

.form-control .fileinput-filename {
  vertical-align: bottom;
}

.fileinput.input-group > * {
  position: relative;
  z-index: 2;
}

.fileinput.input-group > .btn-file {
  z-index: 1;
}

//
// Input Material form state
//

.form-control-danger,
.form-control-success,
.form-control-warning {
  padding-right: 2.25rem;
  background-repeat: no-repeat;
  background-position: center right 0.5625rem;
  -webkit-background-size: 1.125rem 1.125rem;
  background-size: 1.125rem 1.125rem;
}

.has-success .col-form-label,
.has-success .custom-control,
.has-success .form-check-label,
.has-success .form-control-feedback,
.has-success .form-control-label {
  color: $success;
}

.has-success .form-control-success {
  background-image: url(../../../images/icon/success.svg);
}

.has-success .form-control {
  border-color: $success;
}

.has-warning .col-form-label,
.has-warning .custom-control,
.has-warning .form-check-label,
.has-warning .form-control-feedback,
.has-warning .form-control-label {
  color: $warning;
}

.has-warning .form-control-warning {
  background-image: url(../../../images/icon/warning.svg);
}

.has-warning .form-control {
  border-color: $warning;
}

.has-danger .col-form-label,
.has-danger .custom-control,
.has-danger .form-check-label,
.has-danger .form-control-feedback,
.has-danger .form-control-label {
  color: $danger;
}

.has-danger .form-control-danger {
  background-image: url(../../../images/icon/danger.svg);
}

.has-danger .form-control {
  border-color: $danger;
}

//
// Form steps
//

ol.progtrckr {
  list-style-type: none;
  padding: 0;
  text-align: center;
}

ol.progtrckr li {
  display: inline-block;
  text-align: center;
  line-height: 4.5rem;
  cursor: pointer;
}

ol.progtrckr li span {
  padding: 0 1.5rem;
}

@media (max-width: 650px) {
  .progtrckr li span {
    display: none;
  }
}
.progtrckr em {
  display: none;
  font-weight: 700;
  padding-left: 1rem;
}

@media (max-width: 650px) {
  .progtrckr em {
    display: inline;
  }
}

ol.progtrckr li.progtrckr-todo {
  color: silver;
  border-bottom: 4px solid silver;
}

ol.progtrckr li.progtrckr-doing {
  color: black;
  border-bottom: 4px solid #cccccc;
}

ol.progtrckr li.progtrckr-done {
  color: black;
  border-bottom: 4px solid #5cb85c;
}

ol.progtrckr li:after {
  content: "\00a0\00a0";
}

ol.progtrckr li:before {
  position: relative;
  bottom: -65px;
  float: left;
  left: 50%;
}

ol.progtrckr li.progtrckr-todo:before {
  content: "\039F";
  color: silver;
  background-color: white;
  width: 20px;
  line-height: 20px;
}

ol.progtrckr li.progtrckr-todo:hover:before {
  color: #ff4500;
}

ol.progtrckr li.progtrckr-doing:before {
  content: "\2022";
  color: white;
  background-color: #cccccc;
  width: 20px;
  line-height: 20px;
  border-radius: 50px;
}

ol.progtrckr li.progtrckr-doing:hover:before {
  color: #ff4500;
}

ol.progtrckr li.progtrckr-done:before {
  content: "\2713";
  color: white;
  background-color: #5cb85c;
  width: 20px;
  line-height: 20px;
  border-radius: 50px;
}

ol.progtrckr li.progtrckr-done:hover:before {
  color: #333;
}

.footer-buttons {
  text-align: center;
}
