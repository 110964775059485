// 
// Invoice
// 

.total-amount {

    p,
    h3 {
        span {
            width: 120px;
            display: inline-block;
            text-align: right;
        }
    }
}